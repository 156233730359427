import { filter } from "lodash"

const getFilterListItem = (keyList=[],patientListItem={},searchText="")=>{
    for(let itemIndex =0; itemIndex<keyList.length ; itemIndex++ ){
        if(patientListItem[keyList[itemIndex]]?.toLowerCase()?.includes(searchText)){
            return true;
        }
    }

    return false;
}

const getFilteredList = (patientList, searchText) =>{
    return filter(patientList,(patientListItem)=>{

        return getFilterListItem(["firstName","lastName","email","phoneNumber"],patientListItem,(searchText?.toLowerCase() || ""))
    })
}

export {
    getFilteredList
}