import cx from "classnames";

import MealContent from "./components/MealContent";
import MealLogs from "./components/MealLogs";
import styles from "./dailyMealLogs.module.css";
import MealTabelContentBlock from "./components/MealTableContentBlock/MealTabelContentBlock";
import { COLUMN_NAMES } from "./dailyMealLogs.constants";

const getCorrespondingColumns = ()=>{
    return [{
            id : 1,
            label: "Log Time",
            name : COLUMN_NAMES.LOG_TIME,
            className : cx(styles.columnHeaderTypo, styles.logTimeContainer),
            innerClassName : styles.innerClassName
        },
        {
            id : 2,
            name : COLUMN_NAMES.MEAL,
            label: "Meal",
            className : cx(styles.columnHeaderTypo, styles.mealColumnContainer),
            innerClassName : styles.innerClassName
        },
        {
            id : 3,
            name : COLUMN_NAMES.ONE_HOUR_PP,
            label: "1Hr PP",
            className : styles.columnHeaderTypo,
            innerClassName : styles.innerClassName
        },
        {
            id : 4,
            name : COLUMN_NAMES.TWO_HOUR_PP,
            label: "2Hr PP",
            className : styles.columnHeaderTypo,
            innerClassName : styles.innerClassName
        },
        {
            id : 5,
            name : COLUMN_NAMES.RISE_RATE,
            label: "Rise Rate",
            className : styles.columnHeaderTypo,
            innerClassName : styles.innerClassName
        }
    ]
}

const getMealTableFieldWrapper = ()=>{
    return [{
        label : COLUMN_NAMES.LOG_TIME,
        field : MealLogs,
        name : COLUMN_NAMES.LOG_TIME,
    },
    {
        label : COLUMN_NAMES.MEAL,
        field : MealContent,
        name : COLUMN_NAMES.MEAL,
    }
    ,{
        label : COLUMN_NAMES.ONE_HOUR_PP,
        field : MealTabelContentBlock,
        name : COLUMN_NAMES.ONE_HOUR_PP,
    }
    ,{
        label : COLUMN_NAMES.TWO_HOUR_PP,
        field : MealTabelContentBlock,
        name : COLUMN_NAMES.TWO_HOUR_PP,
    },
    {
        label : COLUMN_NAMES.RISE_RATE,
        field : MealTabelContentBlock,
        name : COLUMN_NAMES.RISE_RATE,
    }
]
}

export {
    getCorrespondingColumns,
    getMealTableFieldWrapper
}