const COLUMN_NAMES={
    LOG_TIME : "logTime",
    MEAL : "meal",
    ONE_HOUR_PP : "oneHourPP",
    TWO_HOUR_PP : "twoHourPP",
    RISE_RATE : "riseRate"
}

const MAX_COLOR_PRIORITY = 3;

const COLOR_MAPPINGS ={
    RED : 0,
    YELLOW : 1,
    GREEN : 2,
    UNKNOWN : 3
}

export {
    COLUMN_NAMES,
    COLOR_MAPPINGS,
    MAX_COLOR_PRIORITY
}