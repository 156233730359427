import React from "react";

import emptyIcon from "./../../../assets/images/emptyListFile.svg";

import styles from "./emptyList.module.css";

const EmptyList = () =>{
    return (
        <div className={styles.container}>
            <img src={emptyIcon} className={styles.emptyIcon}/>
            <div className={styles.contentContainer}>
                <div className={styles.mainContentTypo}>
                    No exact match found
                </div>
                <div className={styles.secondaryTextTypo}>
                    Please try search again with phone number or email address and remove filters if any applied
                </div>
            </div>
        </div>
    )
}

export default EmptyList;