
const INIT_ACTION = "INIT_ACTION";
const UPDATE_PROGRESS_MONITORING_DURATION = "UPDATE_PROGRESS_MONITORING_DURATION";
const UPDATE_INTERNAL_TAB = "UPDATE_INTERNAL_TAB";
const UPDATE_COMPARISON_ANALYSIS_TYPE = "UPDATE_COMPARISON_ANALYSIS_TYPE";
const UPDATE_GLUCOSE_LOGS_TAB = "UPDATE_GLUCOSE_LOGS_TAB";
const GET_PATIENTS_DEVICE_DETAILS = "GET_PATIENTS_DEVICE_DETAILS";

export default {
    INIT_ACTION,
    UPDATE_PROGRESS_MONITORING_DURATION,
    UPDATE_INTERNAL_TAB,
    UPDATE_COMPARISON_ANALYSIS_TYPE,
    UPDATE_GLUCOSE_LOGS_TAB,
    GET_PATIENTS_DEVICE_DETAILS
}