import React from "react";
import {map} from "lodash";

import userIcon from "../../../../../../assets/images/male_icon.svg";

import LabelDisplay from "../../labelDisplay/LabelDisplay";

import styles from "./containers.module.css"

const LeftContainer = ({options}) =>{
    return (
    <div className={styles.baseContainer}>
            <div className={styles.profileIconContainer}>
                <img className={styles.profilePhoto} src={userIcon} />                
            </div>
            <div className={styles.contentContainer}>
                {
                    map(options, (option)=>{
                        return <LabelDisplay {...option}/>
                    })
                }
            </div>
    </div>
    )
}

export default LeftContainer;