import {map} from "lodash";
import moment from "moment";

import nonActive from "../../../../../../../../../../../assets/images/noData/noDataAlert.svg"
import { GLUCOSE_METRICS_TYPES } from "../../../../../../../../../../constants/metricTypes";

import { COLOR_MAPPINGS, COLUMN_NAMES, MAX_COLOR_PRIORITY } from "./dailyMealLogs.constants";
import { ALERT_PRIORITY_MAPPING } from "./dailyMealLogs.mapping";

const getMealToMetricsMappedData = (mealLogs=[], mealMetricsData={})=>{
    return map(mealLogs,({
        meal_type_name="",
        logged_time="",
        food_items=[]
    })=>{
        const mealRelatedMetrics = mealMetricsData[meal_type_name] || {};

        return {
            [COLUMN_NAMES.LOG_TIME]:{
                mealType : meal_type_name,
                loggedTime : logged_time,
            },
            [COLUMN_NAMES.MEAL]:{
                meals :food_items
            },
            [COLUMN_NAMES.ONE_HOUR_PP]:{
                metricsData : mealRelatedMetrics[GLUCOSE_METRICS_TYPES.ONE_HOUR_POST_PRANDIAL]
            },
            [COLUMN_NAMES.TWO_HOUR_PP]:{
                metricsData : mealRelatedMetrics[GLUCOSE_METRICS_TYPES.TWO_HOUR_POST_PRANDIAL]
            },
            [COLUMN_NAMES.RISE_RATE]:{
                metricsData : mealRelatedMetrics[GLUCOSE_METRICS_TYPES.POST_PRANDIAL_HIGH_RATE],
            }
        }
    })
}

const getTableFormattedData = (mealLogs={}, metricsData={})=>{
    const mealTableFormattedData = {};
    Object.keys(mealLogs).forEach((mealDateKey)=>{
        const mealMetricsData = metricsData[mealDateKey] || {};
        mealTableFormattedData[mealDateKey]=getMealToMetricsMappedData(mealLogs[mealDateKey], mealMetricsData);    
    })

    return mealTableFormattedData;
}

const getMetricsIteratorForColor = (metricsData, availableMeals=[])=>{
    let minPriorityValue = MAX_COLOR_PRIORITY;
    Object.keys(metricsData).forEach((mealMetricKey)=>{
        if(!availableMeals.includes(mealMetricKey)){
            return;
        }
        Object.values(metricsData[mealMetricKey]).forEach((currentMetrics)=>{
            const currentColorMapping = COLOR_MAPPINGS[currentMetrics?.["glucose-color-value"]?.split(" ")?.[0]] || MAX_COLOR_PRIORITY;
            minPriorityValue=Math.min(minPriorityValue, currentColorMapping);
        })
    })
    return minPriorityValue;
}

const getMealTypeDataMealList = (meals=[])=>{
    const mealSet = new Set();
    meals.forEach((mealItem)=>{
        mealSet.add(mealItem["meal_type_name"])
    });

    return [...mealSet];
}

const getAlertOnDates = (startDate,meal,metricsData) =>{
    const alertAddedDateList = [];
    for(let startDateIndex =0;startDateIndex<7;startDateIndex++){
        const currentDate = moment(startDate,"YYYY-MM-DD").add(startDateIndex,'day').format("YYYY-MM-DD");
        if(!meal[currentDate]){
            alertAddedDateList.push({
                date : currentDate,
                formattedDate : moment(currentDate,"YYYY-MM-DD").format("ddd, MMM DD"),
                alertIcon : nonActive
            })
            continue;
        }
        const availableMeals = getMealTypeDataMealList(meal?.[currentDate] || []);
        const currentMappingValue = getMetricsIteratorForColor(metricsData[currentDate], availableMeals) || MAX_COLOR_PRIORITY;
        alertAddedDateList.push({
            date : currentDate,
            formattedDate : moment(currentDate,"YYYY-MM-DD").format("ddd, MMM DD"),
            alertIcon : ALERT_PRIORITY_MAPPING[currentMappingValue]
        })
    }
    return alertAddedDateList
}

export {
    getTableFormattedData,
    getAlertOnDates
}