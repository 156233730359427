import {map} from "lodash";

const getDateFormattedMealLogs = (glucoseMetricsData)=>{
    let dateMappedGlucoseData = {};
    map(glucoseMetricsData,({
        metricRequest={},
        glucoseDetails={}
    })=>{
        const { date="", mealTypeInString="" ,metricType=""} = metricRequest || {};
        const currentDateObject = dateMappedGlucoseData[date] ||{};
        const mealObject = currentDateObject[mealTypeInString] || {};
        mealObject[metricType]=glucoseDetails;
        currentDateObject[mealTypeInString]=mealObject;
        dateMappedGlucoseData[date]=currentDateObject
    })

    return dateMappedGlucoseData;
}

export {
    getDateFormattedMealLogs
}