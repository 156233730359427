import React from "react";

import styles from "./dateDisplay.module.css"

const DateDisplay = ({startDate, endDate, label ="Current Week"})=>{
    return (
        <div className={styles.container}>
            <div className={styles.mainLabel}>
                {label}
            </div>
            <div className={styles.dateContent}>
                <span>{startDate}</span>
                <span>-</span>
                <span>{endDate}</span>
            </div>
        </div>
    )
}

export default DateDisplay;