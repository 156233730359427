import React from "react";
import moment from "moment";

import styles from "./mealLogs.module.css";

const MealLogs = ({
        data={}
    })=>{
    
    const {mealType="", loggedTime=""} = data;

    return (
        <div className={styles.container}>
            <div className={styles.primaryContent}>
                {mealType}
            </div>
            <div className={styles.secondaryContent}>
                {moment(loggedTime).format('LT')}
            </div>
        </div>
    )
}

export default MealLogs