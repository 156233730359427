import React,{ useEffect, useMemo, useState } from "react";

import withPagination from "../../../../hoc/withPagination";

import Table from "./components/table/Table";
import Search from "./components/search/Search";
import { getPracticePatientDetails } from "./patients.async";
import { getFilteredPatientList } from "./patient.helpers";


const Patients = ({setIsLoading=()=>{}, updateToast=()=>{}}) => {

    const [practicePatientList, setPracticePatientList] = useState([]);

    const [searchText, setSearchText] = useState("");

    useEffect(()=>{
        setIsLoading(true);
        const patientList = getPracticePatientDetails() || new Promise();
        patientList.then(data=>{
            setPracticePatientList(data);
        })
        .finally(()=>{
            setIsLoading(false);
        })
    },[]);

    const handleListReFetch = ()=>{
        setIsLoading(true);
        const patientList = getPracticePatientDetails() || new Promise();
        patientList.then(data=>{
            setPracticePatientList(data);
        })
        .finally(()=>{
            setIsLoading(false);
        })
    }

    const PaginatedTable = withPagination(Table);

    const filteredPatientList = useMemo(()=> getFilteredPatientList(practicePatientList, searchText),[getFilteredPatientList,practicePatientList,searchText]);

    return (
        <div>
            <Search updateSearchText={setSearchText} handleListReFetch={handleListReFetch} setIsLoading={setIsLoading} updateToast={updateToast}></Search>
            <PaginatedTable limit={10} tableData={filteredPatientList} />
        </div>
    )
}

export default Patients;
