import redIcon from "./../../../../../../../../../../../assets/images/criticleSquare.svg";
import yellowIcon from "./../../../../../../../../../../../assets/images/exclamation/yellowFilledExclamation.svg"
import greenCheck from "./../../../../../../../../../../../assets/images/greenCheck.svg";

import noDataIcon from "./../../../../../../../../../../../assets/images/noData/noDataAlert.svg";

const ALERT_PRIORITY_MAPPING=[redIcon,yellowIcon,greenCheck,noDataIcon]

export {
    ALERT_PRIORITY_MAPPING
}