import React from "react";
import cx from "classnames";

import CardHeader from "./components/CardHeader/CardHeader";

import FeaturePauseCard from "../../../../../FeaturePauseCard";

import styles from "./balancedPlate.module.css";

const getBalancedPlateCards = () =>{
    return [
        {
        HeaderComponent : CardHeader,
        BodyComponent : FeaturePauseCard,
        headerProps : {
            label : "Overall Balanced Plate Summary",
            content : "Macro Level Overview on Blood Glucose Tracking",
            className : styles?.summaryHeaderClassname
        },
        containerClassName : styles.summaryContainerClass
    },
    {
        HeaderComponent : CardHeader,
        BodyComponent : FeaturePauseCard,
        headerProps : {
            label : "Weekly Balanced Plate Trend",
            content : "Visual trend of plate score across the week",
            className : styles?.summaryHeaderClassname
        },
        containerClassName : styles.summaryContainerClass
    },
    {
        HeaderComponent : CardHeader,
        BodyComponent : FeaturePauseCard,
        headerProps : {
            label : "Weekly Plate Score Comparison",
            content : "Comparative breakdown of glucose time-in-range across different time segments",
            className : styles?.summaryHeaderClassname
        },
        containerClassName : styles.summaryContainerClass
    }
]
}

export {
    getBalancedPlateCards
}