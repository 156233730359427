import TablePrimaryBlockCard from "../TablePrimaryBlockCard"
import { map } from "lodash"

const getFormattedData = ({data, comparisonMetricSegment }) =>{
    return [{
        "timing" : <TablePrimaryBlockCard primaryText={"Fasting"} comparisonData={comparisonMetricSegment?.["FASTING"]}/>,
        ...data?.[0]?.[0],
        ...data?.[1]?.[0],
    },
    {
        "timing" : <TablePrimaryBlockCard primaryText={"PP Breakfast"} comparisonData={comparisonMetricSegment?.["BREAKFAST"]}/>,
        ...data?.[0]?.[1],
        ...data?.[1]?.[1],
    },
    {
        "timing" : <TablePrimaryBlockCard primaryText={"PP Lunch"} comparisonData={comparisonMetricSegment?.["LUNCH"]}/>,
        ...data?.[0]?.[2],
        ...data?.[1]?.[2],
    },
    {
        "timing" : <TablePrimaryBlockCard primaryText={"PP Dinner"} comparisonData={comparisonMetricSegment?.["DINNER"]}/>,
        ...data?.[0]?.[3],
        ...data?.[1]?.[3],
    }]
}

const getMapToObjectFormat = (data,column) =>{
    let arr=[];
        Object.keys(data).forEach((key)=>{
        arr.push({
            [column]:{
                ...data[key],
            }
        })
    })
    return arr;
}

const getGlucoseFormatedMapper= (data)=>{
    const newData={};
    data?.forEach((key)=>{
        const { metricRequest = {} , glucoseDetails } = key || {};
        const { metricType, mealType } = metricRequest;
        newData[`${metricType} ${mealType}`]= {...glucoseDetails,glucose : glucoseDetails?.glucose && Math.round(glucoseDetails?.glucose)};
    })
    return newData;
}

const getCombinedData = (primary, secondary)=>{
    let newData = map(primary,(data,index)=>{
        let internalMapping = map(data,(internalData,innerIndex)=>{
            let newObj = {};
            if(internalData?.hasOwnProperty('gant1'))
            {
                newObj.gant1={
                    ...internalData?.gant1,
                    ...secondary?.[index]?.[innerIndex]?.gant1
                }
            }
            else{
                newObj.gant2={
                    ...internalData?.gant2,
                    ...secondary?.[index]?.[innerIndex]?.gant2
                }
            }
            return newObj;
        })
        return internalMapping;
    });
    return newData;
}

export {
    getFormattedData,
    getMapToObjectFormat,
    getGlucoseFormatedMapper,
    getCombinedData
}