import InsulinStatus from "./components/InsulinStatus";

import styles from "./patientProfile.module.css";

const PATIENT_DETAILS = [
    {
        primary : 'Basic Detials',
        options : ["firstName","middleName","lastName","sex","patientId"],
    },
    {
        primary : 'Consent Information',
        options : ["renewrxTandC","HippaConsent","dataSharingConsent"],
    },
    {
        primary : 'Contact Details',
        options : ["phoneNumber","email"],
    },
    {
        primary : 'Emergency Contant And Support Persons',
        options : ["emergencyContactName","relationship","contactNumber","alternateNumber"],
    }
];

const PATIENT_DETAILS_MAPPING = {
    fullName : "Patient Name",
    lastName : "Last Name",
    age : "Age",
    email : "Email",
    greeting : "Patient Greeting",
    phoneNumber : "Patient Mobile Number",
    edd : "EDD",
    insulinStatus : "Insulin Status"
}

const PATIENT_DETAILS_COMPONENT_MAPPING = {
    insulinStatus : {
        Component : InsulinStatus,
        selectClassname: styles.selectClassname,
        mainContentTypo : styles.mainContentTypo,
        options:[
            {
                label : "True",
                value : true,
            },
            {
                label : "False",
                value : false,
            }
        ]
    }
}

export {
    PATIENT_DETAILS,
    PATIENT_DETAILS_MAPPING,
    PATIENT_DETAILS_COMPONENT_MAPPING
}