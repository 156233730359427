const DEVICE_DETAIL_MAPPING = [
    "activationTimestamp",
    "expiryDate",
    "lastActive",
    "status"
]

const DEVICE_DETAIL_LABEL_MAPPING={
    "activationTimestamp" : "Start Date",
    "expiryDate" : "End Date",
    "lastActive" : "Last Updated",
    "status" : "Status"

}

export {
    DEVICE_DETAIL_LABEL_MAPPING,
    DEVICE_DETAIL_MAPPING
}