import React from "react";

import styles from "./noData.module.css";

const NoData = () => {
    return (
        <div className={styles.container}>
            <div className={styles.noDataBar} />
        </div>
    )
}

export default NoData;