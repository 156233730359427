import {filter} from "lodash";

const getSearchTextFilteredList = (list=[],searchText="")=>{
    return filter(list,(listItem)=>{
        const {email="", firstName=""} = listItem;

        return (firstName && firstName?.toLowerCase().includes(searchText?.toLowerCase())) || (email && email?.toLowerCase().includes(searchText?.toLowerCase()));
    })
}


export default getSearchTextFilteredList;