import React from "react";

import Modal from "../../../../../../../../molecules/modals/Modal";
import Header from "./components/Header";
import Body from "./components/Body";

import cancelIcon from "./../../../../../../../../../assets/images/cancel/transparent_cacel.svg";

import styles from "./deviceDetailsPopup.module.css";

const DeviceDetailsPopup = (props) => {
    return (
        <div className={styles.container}>
            <Modal
              HeaderComponent={Header}
              BodyComponent={Body}
              className={styles.modalContainer}
              crossIcon={cancelIcon}
              {...props}
            />
        </div>
    )
}

export default DeviceDetailsPopup;