import redCircledCross from "./../../../../../../../assets/images/cancel/circledRedCancel.svg";
import greenCircledTick from "./../../../../../../../assets/images/tickIcon/greenCircledTick.svg";

import redThumb from "./../../../../../../../assets/images/thumb/redThumbUp.svg";
import greenThumb from "./../../../../../../../assets/images/thumb/greenThumbUp.svg";

import styles from "./bloodGlucose.module.css";

const getRespectiveAlert = (data)=>{
    switch(data){
        case "STABLE_GREEN_COLOR" :
            return {
                icon : greenCircledTick,
                content : "Stable",
                contentClass : styles.greenContent
            }
            break;
        case "NO_IMPROVEMENT_RED_COLOR" :
            return {
                icon : redCircledCross,
                content : "No Improvement",
                contentClass : styles.redContent
            }
            break;
        case "IMPROVEMENT_GREEN_COLOR" :
            return {
                icon : greenThumb,
                content : "Improved",
                labelClass : styles.greenContent
            }
            break;
        case "DECLINED_RED_COLOR" :
            return {
                icon : redThumb,
                content : "Declined",
                labelClass : styles.redContent
            }
            break;

        default :
            break;
    }

    return {}
}

export {
    getRespectiveAlert
}