import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";

import CardHeader from "../CardHeader/CardHeader";
import SecondaryHeader from "./components/SecondaryHeader";
import BaseTable from "../../../../../../../../../tables/BaseTable";
import { getCorrespondingColumns, getMealTableFieldWrapper } from "./dailyMealLogs.columns";

import styles from "./dailyMealLogs.module.css";
import { getAlertOnDates, getTableFormattedData } from "./dailyMealLogs.helpers";
import CardNoDataFallback from "../../../../../../../../../../molecules/cardNoDataFallback/CardNoDataFallback";

const DailyMealLogs = ({
        mealLogs={},
        metricsData={},
        startDate =""
    }) =>{

    const [activeDate, setActiveDate] = useState(startDate);

    useEffect(()=>{
        setActiveDate(startDate);
    },[startDate]);

    const tableColumns = useMemo(()=> getCorrespondingColumns(),[]);
    const tableFormattedData = useMemo(()=> getTableFormattedData(mealLogs,metricsData),[mealLogs, metricsData]);

    const tableFields = useMemo(()=> getMealTableFieldWrapper(),[]);

    const dateList = useMemo(()=>getAlertOnDates(startDate, mealLogs, metricsData),[startDate,mealLogs,metricsData,getAlertOnDates])

    const handleActiveDateUpdate = useCallback((updatedDate)=>()=>{
        setActiveDate(updatedDate)
    },[setActiveDate]);

    return (
        <div className={styles.container}>
            <CardHeader
              label={"Daily Meal Log"}
              content={"List of all meals logged for the respective day"}
              className={styles.topHeaderClass}
            />
            <SecondaryHeader 
                dateList={dateList} 
                activeDate={activeDate}
                handleActiveDateUpdate={handleActiveDateUpdate}
            />
            {(!mealLogs || Object.keys(mealLogs?.[activeDate] || {}).length==0) && 
                <CardNoDataFallback 
                    containerClass={styles.noDataContainerClass}
                    textClass={styles.secondaryTextClass}
                />
            }
            {
                Object.keys(mealLogs?.[activeDate] || {}).length>0 && 
                <BaseTable
                    columnsData={tableColumns} 
                    wrapperFields={tableFields}
                    tableData={tableFormattedData[activeDate]}
                    tableDataProps={{
                        className : styles.rowContainer
                    }}
                    columnProps={{
                        className: styles.columnHeaderRowProps
                    }}
                />
            }
        </div>
    )
}

export default DailyMealLogs;