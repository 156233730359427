import React from "react";
import cx from "classnames";

import { getRespectiveAlert } from "../../../../../OverViewTab/components/bloodGlucose/bloodGlucose.mapping";

import styles from "./tablePrimaryBlockCard.module.css";

const TablePrimaryBlockCard = ({primaryText="", comparisonData={}}) =>{
    const {color = "", value="" } = comparisonData || {};

    const {icon , content, labelClass="", contentClass=""} = getRespectiveAlert(color);

    return (
        <div className={styles.container}>
            <div className={styles.primaryText}>
                {primaryText}
            </div>
            <div className={styles.alertContainer}>
                {
                    icon &&
                    <>
                        <img src={icon} className={styles.alertMetricIcon}/>
                        {value!=undefined && value!=0 && value!="" && <span className={cx(styles.alertContent, labelClass)}>{value+"%"}</span>}
                        <span className={cx(styles.alertContent, contentClass)}>{content}</span>
                    </>
                }
                {
                    !icon && <span className={styles.noData}>NA</span>
                }
            </div>
        </div>
    )
}

export default TablePrimaryBlockCard;