import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomePage from "./components/screens/home/index";
import ManageActionPlan from "./components/screens/ManageActionPlan";
import ActionPlan from "./components/screens/ActionPlan";
import SignUp from "./components/organisms/signUp/SignUp";
import Login from "./components/screens/login"
import ResetPassword from "./components/screens/resetPassword";
import PracticeCreateAccount from "./components/screens/practiceCreateAccount/PracticeCreateAccount";
import DashBoard from "./components/screens/dashBoard/DashBoard";
import CommonHeader from "./components/organisms/commonHeader";
import CommonSidebar from "./components/organisms/commonSidebar";
import ImperativeRoutes from "./ImperativeHandler";
import PatientProfile from "./components/organisms/patientProfile";
import UserSetting from "./components/organisms/userSetting/UserSetting";
import Patients from "./components/screens/patients";
import PatientInviteConnect from "./components/organisms/patientInviteConnect"
import TrendAnalysis from "./components/charts/TrendAnalysis/TrendAnalysis";
import PracticeManagement from "./components/screens/practiceManagement/PracticeManagement";
import PracticeManagementCommonHeader from "./components/organisms/practiceManagementCommonHeader/PracticeManagementCommonHeader";
import PracticeManagementCommonSidebar from "./components/organisms/practiceManagementCommonSidebar/PracticeManagementCommonSidebar";
import PracticeDetail from "./components/screens/practiceDetail/PracticeDetail";
import RrxCoach from "./components/screens/rrxCoach/RrxCoach";
import MemberDetail from "./components/screens/memberDetail/MemberDetail";

import CoachDetails from "./components/screens/coachDetails";

export default function MainApp() {

  console.log(process.env)

  return (
      <Router>
            <Routes>
              <Route exact path="/invitePatientConnect" element={<PatientInviteConnect />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/signUp" element={<SignUp />} />
              <Route exact path="/changePassword" element={<ResetPassword />} />
              <Route exact path="/createAccount" element={<PracticeCreateAccount />} />
              <Route exact path="/" element={<ImperativeRoutes ChildElement={< CommonHeader><CommonSidebar><Patients /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/patient" element={<ImperativeRoutes ChildElement={< CommonHeader><CommonSidebar><HomePage /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/manageActionPlan" element={<ImperativeRoutes ChildElement={< CommonHeader><CommonSidebar><ManageActionPlan /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/actionPlan" element={<ImperativeRoutes ChildElement={< CommonHeader><CommonSidebar><ActionPlan /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/dashBoard" element={<ImperativeRoutes ChildElement={< CommonHeader><CommonSidebar><DashBoard /></CommonSidebar></CommonHeader>} />} />
              <Route exact path={`/patients/:id`} element={<ImperativeRoutes ChildElement={<CommonHeader><CommonSidebar><PatientProfile /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/settings" element={<ImperativeRoutes ChildElement={<CommonHeader><CommonSidebar><UserSetting /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/invitePatient" element={<ImperativeRoutes ChildElement={<CommonHeader><CommonSidebar><Patients /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/splineChart" element={<ImperativeRoutes ChildElement={<CommonHeader><CommonSidebar><TrendAnalysis /></CommonSidebar></CommonHeader>} />} />
              <Route exact path="/superuser" element={<ImperativeRoutes ChildElement={<PracticeManagementCommonHeader><PracticeManagementCommonSidebar><PracticeManagement /></PracticeManagementCommonSidebar></PracticeManagementCommonHeader>} />} />
              <Route exact path={`/superUser/:practiceId/:practiceName`} element={<ImperativeRoutes ChildElement={<PracticeManagementCommonHeader><PracticeManagementCommonSidebar><PracticeDetail /></PracticeManagementCommonSidebar></PracticeManagementCommonHeader>} />} />
              <Route exact path={`/superUser/member/:memberId/:practiceName`} element={<ImperativeRoutes ChildElement={<PracticeManagementCommonHeader><PracticeManagementCommonSidebar><MemberDetail /></PracticeManagementCommonSidebar></PracticeManagementCommonHeader>} />} />
              <Route exact path={`/superUser/pacticeMember/:practiceId/:memberId/:practiceName`} element={<ImperativeRoutes ChildElement={<PracticeManagementCommonHeader><PracticeManagementCommonSidebar><MemberDetail /></PracticeManagementCommonSidebar></PracticeManagementCommonHeader>} />} />
              <Route exact path="/rrxCoach" element={<ImperativeRoutes ChildElement={<PracticeManagementCommonHeader><PracticeManagementCommonSidebar><RrxCoach /></PracticeManagementCommonSidebar></PracticeManagementCommonHeader>} />} />
              <Route exact path="/rrxCoach/coachDetails/:practiceId/:coachId" element={<ImperativeRoutes ChildElement={<PracticeManagementCommonHeader><PracticeManagementCommonSidebar><CoachDetails /></PracticeManagementCommonSidebar></PracticeManagementCommonHeader>} />} />
              <Route exact path="/superUser/practice/member" element={<ImperativeRoutes ChildElement={<PracticeManagementCommonHeader><PracticeManagementCommonSidebar><MemberDetail></MemberDetail></PracticeManagementCommonSidebar></PracticeManagementCommonHeader>} />} />
            </Routes>
      </Router>
  );
}
