import {map, filter} from "lodash";
import Label from "../../../atoms/label";

const getBaseTableRowProps = (columnsProps, rowData) =>{
    const updatedRowData = map(rowData,(value,key)=>{
        return {
            className: columnsProps[key]?.className,
            ...value,
        }
    })
    return updatedRowData;
}

const getChildColumnProps= (columnData,key)=>{
    let column=filter(columnData,({name})=> name==key)
    return {
        className :column?.[0]?.className || "",
        innerClassName :column?.[0]?.innerClassName || ""
    };
}

const getTableFields = (data=[])=>{
    let columns ={};
    data.forEach(({name,label})=>{
        columns[name]=label
    })

    return columns;
}

const getBaseTableWrapperFields = (wrapperFields,dataFields={},columnProps) =>{
    if(Array.isArray(dataFields)){
        return map(columnProps,(column)=>{
            const {name: field, className, innerClassName} = column
            const [mapper] = filter(wrapperFields,({label})=> {
                if(typeof field == "object")
                {
                    return field?.label == label
                }
                return label == field
            })
            const {field : WrapperComponent = Label }= mapper || {};
    
            return {
                label : (restData)=> <WrapperComponent className={className || ""} {...field} {...dataFields} {...restData}/>,
                    ...getChildColumnProps(columnProps,field?.name) || "",
                    innerClassName,
                    className
                } 
        })
    }
    return map(columnProps,(column)=>{
        const {name: field, className, innerClassName} = column
        const [mapper] = filter(wrapperFields,({label})=> {
            return label == field
        })

        const {field : WrapperComponent = Label, columnInnerClass }= mapper || {};

        return {
            label : (restData) =><WrapperComponent data={dataFields[field]} {...dataFields} {...restData}/>,
            ...getChildColumnProps(columnProps,field) || "",
            innerClassName: columnInnerClass ? columnInnerClass :innerClassName,
            className
        } 
    })
}
export {
    getBaseTableRowProps,
    getBaseTableWrapperFields,
    getTableFields
}