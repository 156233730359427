import React from "react";
import cx from "classnames";

import PropTypes from "prop-types";

import styles from "./largeCards.module.css";

const LargeCards = ({HeaderComponent, headerProps, BodyComponent, bodyProps, containerClassName, ...rest}) =>{
    
    const { bodyClassname="" } = bodyProps || {};
    return (
        <div className={cx(styles.container, containerClassName)}>
            <HeaderComponent {...headerProps} {...rest} />
            <div className={cx(styles.body, bodyClassname)}>
                <BodyComponent {...bodyProps} {...rest} />
            </div>
        </div>
    )
}

LargeCards.propTypes={
    HeaderComponent : PropTypes.element,
    headerProps: PropTypes.object,
    BodyComponent : PropTypes.element,
    bodyProps : PropTypes.object
}


export default LargeCards;