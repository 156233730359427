import moment from "moment";
import { BASE_URL } from "../../../../../../../../constants/api";
import { LOCAL_STORAGE_KEYS } from "../../../../../../../../constants/keyConstants";
import { getWeeklyFormattedWeeklyDates, getWeeklyFormattedWeeklyDatesOnStartDate } from "../../../../../../../../helpers/dateFormatter";
import { getRequestBodyOnStartDate } from "../../../../progressMonitoring.helper";
import { getDateFormattedMealLogs } from "./balancedPlate.helpers";

const getWeeklyPlateScore = async({state, setState, payload})=>{
    try{
        const {userId="", prev=0 } = payload ||{};
        const {startDate : start_date, endDate: end_date} = getWeeklyFormattedWeeklyDates({prev:prev,format:'YYYY-MM-DD'});

        const previousWeekResponse = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${userId}/${start_date}/${end_date}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });

        const previousWeekMicroNutritionalValues = await previousWeekResponse.json();

        setState((prev)=>{
            return {
                ...prev,
                previousWeekMicroNutritionalValues
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        })
    }
}

const getNutritionalInsights = async({state, setState,payload}) =>{
    try{
        const {userId="" ,startDate="", endDate=""} = payload;

        const nutritionalInsights = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${userId}/${startDate}/${endDate}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });

        const nutritionalInsightsDaily = await nutritionalInsights.json();

        const {micronutrientsInsightsResponseMap} = nutritionalInsightsDaily;

        setState((prev)=>{
            return {
                ...prev,
                micronutrientsInsightsResponseMap
            }
        })

    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        })
    }
}

const getSummaryBlockDetails = async({state, setState, payload})=>{
    try{
        const {userId, startDate, endDate} = payload;

        const response = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${userId}/${startDate}/${endDate}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });
        const microNutritionalValues = await response.json();

        const {percentageOfBalancedPlateMealStatusMap, micronutrientsInsightsResponse} = microNutritionalValues;

        setState((prev)=>{
            return {
                ...prev,
                micronutrientsInsightsResponse,
                percentageOfBalancedPlateMealStatusMap
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error: e,
            }
        })
    }
}

const getSummaryDetails = async({state, setState, payload})=>{
    try{
        const {userId, startDate, endDate} = payload;

        const {startDate: start_date, endDate : end_date} = getWeeklyFormattedWeeklyDates({prev:1,format:"YYYY-MM-DD"});

        const response = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${userId}/${startDate}/${endDate}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });
        const microNutritionalValues = await response.json();

        const previousWeekResponse = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/macronutrientsInsights/${userId}/${start_date}/${end_date}`,{
            method:"GET",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            }
        });
        const previousWeekMicroNutritionalValues = await previousWeekResponse.json();

        setState((prev)=>{
            return {
                ...prev,
                ...microNutritionalValues,
                previousWeekMicroNutritionalValues,
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error: e,
            }
        })
    }
}

const getDailyMealLogs = async({
        state={},
        setState=()=>{},
        payload={}
    })=>{

    try{
        const {userId, startDate, endDate } = payload;

        const response = await fetch(`${BASE_URL}/nourishmentNotebook/dashboard/foodLogs/${userId}/${startDate}/${endDate}`, {
            method: "GET",
            headers: {
              "Authorization": "Bearer " + window.localStorage.getItem("Authorization")
            }
          });

          const data = await response.json();

          const oneHourPPBreakfast = getRequestBodyOnStartDate({type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"BREAKFAST", startDate: moment(startDate,'YYYY-MM-DD').format('MM-DD-YYYY')});
          const oneHourPPLunch = getRequestBodyOnStartDate({type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"LUNCH", startDate: moment(startDate,'YYYY-MM-DD').format('MM-DD-YYYY')});
          const oneHourPPDinner = getRequestBodyOnStartDate({type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"DINNER", startDate: moment(startDate,'YYYY-MM-DD').format('MM-DD-YYYY')});

          const twoHourPPBreakfast = getRequestBodyOnStartDate({type:"TWO_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"BREAKFAST", startDate: moment(startDate,'YYYY-MM-DD').format('MM-DD-YYYY')});
          const twoHourPPLunch = getRequestBodyOnStartDate({type:"TWO_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"LUNCH", startDate: moment(startDate,'YYYY-MM-DD').format('MM-DD-YYYY')});
          const twoHourPPDinner = getRequestBodyOnStartDate({type:"TWO_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"DINNER", startDate: moment(startDate,'YYYY-MM-DD').format('MM-DD-YYYY')});

          const riseRateBreakfast = getRequestBodyOnStartDate({type:"POST_PRANDIAL_HIGH_RATE",numberOfDays: 7,mealType:"BREAKFAST", startDate: moment(startDate,'YYYY-MM-DD').format('MM-DD-YYYY')});
          const riseRateLunch = getRequestBodyOnStartDate({type:"POST_PRANDIAL_HIGH_RATE",numberOfDays: 7,mealType:"LUNCH", startDate: moment(startDate,'YYYY-MM-DD').format('MM-DD-YYYY')});
          const riseRateDinner = getRequestBodyOnStartDate({type:"POST_PRANDIAL_HIGH_RATE",numberOfDays: 7,mealType:"DINNER", startDate: moment(startDate,'YYYY-MM-DD').format('MM-DD-YYYY')});

          const oneHourPPResponse = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem("Authorization"),
            },
            body: JSON.stringify({
                userId: userId,
                metricRequests:[
                    ...oneHourPPBreakfast,
                    ...twoHourPPBreakfast,
                    ...riseRateBreakfast,
                    ...oneHourPPLunch,
                    ...twoHourPPLunch,
                    ...riseRateLunch,
                    ...oneHourPPDinner,
                    ...twoHourPPDinner,
                    ...riseRateDinner
              ]
            })
        })
    
        const metricsData = await oneHourPPResponse.json();

        setState((prev)=>{
            return {
                ...prev,
                foodLogs : data,
                metricsData : getDateFormattedMealLogs(metricsData?.metricsResponses || [])
            }
        });
    
    }
    catch(e){
        console.log('ERROR IS : ',e);
    }
}

const getInitAction = async({state, setState}) =>{
    try{
        const {currentPreviousWeek = {}, weeklyFilter={}} = state;

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const {startDate, endDate} = getWeeklyFormattedWeeklyDatesOnStartDate({format:"YYYY-MM-DD"});
        
        getDailyMealLogs({state, setState, payload:{
            userId : pathname?.[2],
            startDate,
            endDate
        }});

        setState((prev)=>{
            return {
                ...prev,
                weeklyFilter:{
                    "current":startDate,
                }
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error: e,
            }
        })
    }
}

const getFilterChangeAction = ({state, setState, payload}) =>{
    try{
        const {handleToggleLoaderAction} = state;
        const {name, startDate} = payload;
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        handleToggleLoaderAction({isLoading : true});

        switch(name){
            default:
                getDailyMealLogs({
                    state,
                    setState,
                    payload:{
                        ...payload,
                        userId : pathname?.[2],
                    }
                })
                .then(()=>{
                    handleToggleLoaderAction({isLoading : false});
                })
                break;
        }

        setState((previousState)=>{
            const {weeklyFilter} = previousState;
            return {
                ...previousState,
                weeklyFilter:{
                    ...weeklyFilter,
                    [name]: startDate
                }
            }
        });
    }
    catch(e){
        handleToggleLoaderAction({isLoading : false});
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        })
    }
}

export default {
    INIT_ACTION : getInitAction,
    FILTER_UPDATE_ACTION : getFilterChangeAction
}