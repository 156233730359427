import React from "react";
import cx from "classnames";

import exclamationIcon from "./../../../../../../../../../assets/images/exclamation/greyExclamation.svg";

import BaseTable from "../../../../../../../tables/BaseTable";
import { getTrendAnalysisTableFields, getTrendAnalysisTableWrapperFields } from "./trendAnalysisTable.fields";
import { getAbnormalities, getCopyFormattedData, getTrendAnalysisTableFormattedData } from "./trendAnalysisTable.helpers";

import { ALERT_ICONS, MEAL_MARKERS, WAKEUP_TIME_MARKER } from "./trendAnalysisTable.mapping.js";
import { TAB_OPTIONS } from "./trendAnalysisTable.constants.js";

import FooterLabelBlock from "./components/footerLabelBlock/FooterLabelBlock.js";
import { GLUCOSE_METRICS_SOURCE_TYPES } from "../../../../../../../../constants/metricTypes.js";

import styles from "./trendAnalysisTable.module.css"

const TrendAnalysisTable = ({trendAnalysisTableData=[], handleGlucoseLogsTabUpdateAction=()=>{}, previous, handleToastUpdateAction, glucoseLogMetricsSourceTab=""}) =>{

    const tableData = getTrendAnalysisTableFormattedData(trendAnalysisTableData, previous);

    const abnormalities = getAbnormalities(tableData);

    const handleTabClickAction=(tab)=>()=>{
        handleGlucoseLogsTabUpdateAction({updatedStartDate: previous, tab});
    }

    const handleTableDataCopyAction = async() =>{
        try{
            let copyData = getCopyFormattedData(trendAnalysisTableData, tableData);
            navigator.clipboard.writeText(copyData)
            .then(()=>{handleToastUpdateAction({
                enableToast: true,
                toastProps :{
                    message : "Glucose Log Sheet successfully copied to Clipboard",
                    type : "success"
                }
            })})
        }
        catch(e){
            console.log('Copy Failed');
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.topContainer}>
                <div className={styles.topLeftContainer}>
                    <div className={styles.primaryText}>
                            <span className={styles.primaryText}>Glucose Log Sheet</span>
                            <img src={exclamationIcon} className={styles.exclamationIcon}/>
                            <span className={styles.betaTagContainer}> Beta </span>
                    </div>
                    <div className={styles.secondaryText}>
                    Patient provided (or auto detected) 1 hour post prandial glucose value based on time of date
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.copyDataOuterContainer} onClick={handleTableDataCopyAction}>
                        <div className={styles.copyDataInnerContainer}>Copy Data</div>
                    </div>
                    <div className={styles.tabOptionContainer}>
                        <div 
                            onClick={handleTabClickAction(GLUCOSE_METRICS_SOURCE_TYPES.DEFAULT_VALUE)} 
                            className={cx(styles.tabOptionsInnerContainer, styles.tabOptionsInnerLeft,{[styles.activeTabOption]: glucoseLogMetricsSourceTab == GLUCOSE_METRICS_SOURCE_TYPES.DEFAULT_VALUE})}
                        >
                            {TAB_OPTIONS.HYBRID}
                        </div>
                        <div 
                            onClick={handleTabClickAction(GLUCOSE_METRICS_SOURCE_TYPES.CGM)} 
                            className={cx(styles.tabOptionsInnerContainer, styles.tabOptionsInnerCenter,{[styles.activeTabOption]: glucoseLogMetricsSourceTab == GLUCOSE_METRICS_SOURCE_TYPES.CGM})}
                        >
                            {TAB_OPTIONS.CGM}
                        </div>
                        <div 
                            onClick={handleTabClickAction(GLUCOSE_METRICS_SOURCE_TYPES.BGM)} 
                            className={cx(styles.tabOptionsInnerContainer, styles.tabOptionsInnerRight,{[styles.activeTabOption]: glucoseLogMetricsSourceTab == GLUCOSE_METRICS_SOURCE_TYPES.BGM})}
                        >
                            {TAB_OPTIONS.BGM}
                        </div>
                    </div>
                </div>
            </div>
            <BaseTable 
                columnProps={{ className : styles.columnRow}}
                wrapperFields={getTrendAnalysisTableWrapperFields()} 
                columnsData={getTrendAnalysisTableFields()} 
                tableData={tableData} 
                tableDataProps={{className : styles.tableRow }}
            />
            <div className={styles.anormalitiesContainer}>
                <div className={cx(styles.primaryAbnormalitiesContainer,styles.primaryTextAbnormalContainer)}>
                    Abnormalities
                </div>
                <div className={`${styles.primaryAbnormalitiesContainer} ${styles.abnormalitiesTextContainer}`}>
                    {( abnormalities.fasting.count !=0 ? Math.floor((abnormalities.fasting.abnormal/abnormalities.fasting.count)*100) :0 )+"%  "}
                    {"("+abnormalities.fasting.abnormal}/{abnormalities.fasting.count+")"}
                </div>
                <div className={`${styles.primaryAbnormalitiesContainer} ${styles.abnormalitiesTextContainer}`}>
                    {(abnormalities.breakfast.count!=0 ? Math.floor((abnormalities.breakfast.abnormal/abnormalities.breakfast.count)*100) :0)+"%  "}
                    {"("+abnormalities.breakfast.abnormal}/{abnormalities.breakfast.count+")"}
                </div>
                <div className={`${styles.primaryAbnormalitiesContainer} ${styles.abnormalitiesTextContainer}`}>
                    {(abnormalities.lunch.count!=0 ? Math.floor((abnormalities.lunch.abnormal/abnormalities.lunch.count)*100) : 0)+"%  "}
                    {"("+abnormalities.lunch.abnormal}/{abnormalities.lunch.count+")"}
                </div>
                <div className={`${styles.primaryAbnormalitiesContainer} ${styles.abnormalitiesTextContainer}`}>
                    {(abnormalities.dinner.count!=0 ? Math.floor((abnormalities.dinner.abnormal/abnormalities.dinner.count)*100) : 0)+"%  "}
                    {"("+abnormalities.dinner.abnormal}/{abnormalities.dinner.count+")"}
                </div>
            </div>
            <div className={styles.footerContainer}>
                <div className={styles.footerLeftContainer}>
                    <FooterLabelBlock label={"Meal"} options={MEAL_MARKERS} />
                    <FooterLabelBlock label={"Wakeup Time"} options={WAKEUP_TIME_MARKER} />
                </div>
                <div className={styles.footerRightContainer}>
                    <FooterLabelBlock label={"Alerts"} options={ALERT_ICONS} />
                </div>
            </div>
        </div>
    )
}

export default TrendAnalysisTable;