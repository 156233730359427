import React from "react";
import {map} from "lodash";

import emptyImage from "./../../../../../../../../../../../../../assets/images/emptyImage/emptyImage.svg";

import styles from "./mealContent.module.css";

const MealContent = ({
        data
    }) =>{
    const { meals } = data || {};
    return (
        <div className={styles.container}>
            { (!meals[0]?.image_url || meals[0]?.image_url?.length==0) && <div className={styles.emptyImageIconContainer}>
                <img src={emptyImage} className={styles.emptyImageIcon} />
                </div>}
            {meals[0]?.image_url && meals[0]?.image_url?.length>0 && <img src={ meals[0]?.image_url} className={styles.mealIcon} />}
            <div className={styles.mealList}>
                {map(meals,({
                    food_name=""
                })=>{
                    return (<div className={styles.mealListItem}>{food_name}</div>)
                })}
            </div>
        </div>
    )
}

export default MealContent