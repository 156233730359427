import React from "react";
import { useNavigate } from "react-router";
import {map} from "lodash";

import { getTextInitials } from "../../../../helpers/TextManipulations.helpers";
import { TABS } from "./table.constants";

import { RRX_MEMBER_ROLES, RRX_PRACTICE_ID } from "../../rrxCoach.constants";
import styles from "./table.module.css";
import ActionItem from "../ActionItem";
import InvitedActionItem from "../InvitedActionItem";
import ActiveActionItem from "../ActiveActionItem";

const Table = ({ 
        tableData:listItems=[], 
        resentInviteAction=()=>{}, 
        tab ="",
        setIsLoading = ()=>{} ,
        updateCoachList=()=>{},
        handleOpenToastDisplay=()=>{}
    }) => {

    const navigate = useNavigate();

    const handleResendInviteAction =(id)=>()=>{
        if(tab==TABS.ACTIVE){
            navigate(`/rrxCoach/coachDetails/${RRX_PRACTICE_ID}/`+id);
            return;
        }
        else if(tab==TABS.ARCHIVED){
            return;
        }
        resentInviteAction(id);
    }

    return (
        
            <div className={styles.practiceListContainer}>
                <div className={styles.tableHeader}>
                    <div className={styles.tableColumn}>NAME<img src={require("../../../../../assets/images/sort-icon.svg").default} className={styles.searchLogo}></img></div>
                    <div className={styles.tableColumn}>EMAIL</div>
                    <div className={styles.tableColumn}>ROLE</div>
                    <div className={styles.tableColumnAction}>ACTION</div>
                </div>
                <div className={styles.tableColumnContainer}>
                    {
                        map(listItems,(item)=>{
                            const {id="", firstName="" ,lastName="",  role="",email="" } = item || {};
                            const fullname = (firstName || "")+" "+(lastName || "");
                            return (
                                <div className={styles.tableColumnData}>
                                <div className={styles.tableColumnDataContainer}>
                                    <div className={styles.avatar}>
                                        <div className={styles.avatarData}>
                                            {getTextInitials(fullname)}
                                        </div>
                                    </div>
                                    <div className={styles.nameAndUrlContainer}>
                                        <div className={styles.practiceName}>
                                            {fullname}
                                        </div>
                                    </div>
                                </div>
        
                                <div className={styles.tableColumnDataContainer}>
                                    <div className={styles.nameAndUrlContainer}>
                                        <div className={styles.practiceName}>
                                            {email}
                                        </div>
                                    </div>
                                </div>
        
                                <div className={styles.tableColumnDataContainer}>
                                    <div className={styles.nameAndUrlContainer}>
                                        <div className={styles.userRoleContainer}>
                                            {RRX_MEMBER_ROLES[role]}
                                        </div>
                                    </div>
                                </div>
        
                                <div className={styles.tableColumnDataContainer}>
        
                                    <div className={styles.resendInvite}>
                                        {tab == TABS.INVITED &&
                                            <InvitedActionItem
                                                coachId={id}
                                                setIsLoading={setIsLoading} 
                                                updateCoachList={updateCoachList}
                                                handleOpenToastDisplay={handleOpenToastDisplay}
                                                handleResendInviteAction={handleResendInviteAction(id)}
                                            />
                                        }
                                        {tab == TABS.ARCHIVED && 
                                            <ActionItem 
                                                coachId={id} 
                                                setIsLoading={setIsLoading} 
                                                updateCoachList={updateCoachList}
                                                handleOpenToastDisplay={handleOpenToastDisplay}
                                            />}
                                        { tab == TABS.ACTIVE &&
                                            <ActiveActionItem
                                                coachId={id}
                                                setIsLoading={setIsLoading} 
                                                updateCoachList={updateCoachList}
                                                handleOpenToastDisplay={handleOpenToastDisplay}
                                                handleViewDetailsAction={handleResendInviteAction(id)}
                                            />
                                        }
                                    </div>
        
                                </div>
        
                                </div>        
                            )
                        })
                    }

                </div>
            </div>
            
       


    )
}

export default Table;
