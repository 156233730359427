import { filter } from "lodash";

const getSearchTextFilteredList = (list=[],searchText="")=>{
    return filter(list,(listItem)=>{
        const {firstName="" ,lastName="", email=""} = listItem;

        return (email && email.toLowerCase().includes(searchText?.toLowerCase())) || (firstName && firstName.toLowerCase().includes(searchText?.toLowerCase())) || (lastName && lastName.toLowerCase().includes(searchText?.toLowerCase()));
    })
}

export {
    getSearchTextFilteredList
}