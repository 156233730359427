import React from "react";
import cx from "classnames";

import noDataBunny from "./../../../assets/images/no_data_bunny.svg";

import { NO_DATA_ENUMS } from "./cardNoDataFallback.constants";

import styles from "./cardNoDataFallback.module.css";

const CardNoDataFallback = ({containerClass="", textClass=""}) =>{
    return (
        <div className={cx(styles.container, containerClass)}>
            <img src={noDataBunny} alt="No Data" className={styles.iconContainer} />
            <div className={cx(styles.textContainer, textClass)}>
                <div className={styles.primaryText}>
                    {NO_DATA_ENUMS.HEADING}
                </div>
                <div className={cx(styles.secondaryText)}>
                    {NO_DATA_ENUMS.TEXT}
                </div>
            </div>
        </div>
    )
}

export default CardNoDataFallback;