import React from "react";

import styles from "./deviceManagementDrawerHeader.module.css";

const DeviceManagementDrawerHeader = ({
        CancelComponent = () => <></>
    }) =>{
        
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                Device Management
            </div>
            <div className={styles.rightContainer}>
                <CancelComponent />
            </div>
        </div>
    )
}

export default DeviceManagementDrawerHeader