import moment from "moment"
import {filter, map} from "lodash";
import { PATIENT_DETAILS, PATIENT_DETAILS_COMPONENT_MAPPING, PATIENT_DETAILS_MAPPING } from "./patientProfile.mappings";

import styles from "./patientProfile.module.css";
import OverViewTab from "./components/OverViewTab";
import PatientInfo from "./components/PatientInfo/PatientInfo";
import ProgressMonitoring from "./components/progressMonitoring/ProgressMonitoring";
import { PATIENT_DETAILS_ENUMS } from "../../constants/UserDetails";
import { getUpperCaseFormatter } from "../../helpers/TextManipulations.helpers";
import { DURATION_CHANGE_TYPES } from "./patientProfile.constants";
import { getWeeklyFormattedWeeklyDates } from "../../helpers/dateFormatter";
import BalancedPlate from "./components/progressMonitoring/components/Body/components/BalancedPlate/BalancedPlate";

const getRequestBody = ({type,numberOfDays, prev =0,mealType})=>{
    let metrics =[]
    for(let x =2 ; x<=numberOfDays;x++)
    {
        metrics.push({
            "metricType":type,
            "date": moment().subtract(8-(x%9)+(7*prev),'d').format("YYYY-MM-DD"),
            "mealType": mealType
        })
    }
    return metrics;
}

const getTabComponent = (activeTab) =>{
    switch(activeTab)
    {
        case "Patient Info":
            return PatientInfo;
            break;
        case "Glucose":
            return ProgressMonitoring;
            break;
        case "Balanced Plate":
            return BalancedPlate
            break;
        default : 
            return OverViewTab;
            break;
    }
}

const getRecentTrendsFormatter = (recentTrends)=>{
    return map(filter(recentTrends?.bloodGlucoseTrendDataList,({
        trendAgeDays
    })=>{
        return trendAgeDays>0
    }),({
        message,
        range,
        trendAgeDays
    })=>{
        return {
            label : message,
            range,
            trendAgeDays,
        }
    })
}

const getTabProps=({overviewDuration, balancePlateValues, overViewDurationTypes, handleTabChange, activeTab,handleOverDurationChange, handleNotesDisplayAction,currentProgressMonitoringType})=>{
    switch(activeTab)
    {
        case "Patient Info":
            return {

            };
            break;
        case "Glucose":
            return {
                handleTabChange,
                progressMonitoringType:currentProgressMonitoringType
            };
            break;
        case "Balanced Plate":
            return {
                handleTabChange,
                progressMonitoringType:currentProgressMonitoringType
            }
            break;
        default : 
            return {
                overviewDuration,
                handleOverDurationChange,
                handleTabChange,
                overViewDurationTypes,
                balancePlate : balancePlateValues,
                handleNotesDisplayAction
            };
            break;
    }
}

const getPatientDetailsFormattedData = (data={})=>{
    return map(PATIENT_DETAILS,(detail)=>{
        return {
            label : detail.primary,
            className : styles.profileTabOption,
            textClassName : styles.profileTabOptionText,
            innerContainerClass : styles.innerContainerClass,
            options : map(detail.options,(option)=>{
                return {
                    label : PATIENT_DETAILS_ENUMS[option],
                    className : styles.infoTextContainer,
                    value : data?.[option] || "No Data"
                }
            })   
        }
    })
}

const getCorrespndingUserDetails = (type,value)=>{
    if(value==null || value == undefined || value==""){
        return value;
    }
    switch(type){
        case "insulinStatus" : 
            return value==true ? "True" : "False";
        case "fullname" :
            return getUpperCaseFormatter(value);
        case "edd":
            return moment(value,"YYYY-MM-DD").format("MMM DD, YYYY")
    }
    return value
}

const getCorrespndingLabelProps = (name)=>{
    switch(name){
        case "fullName":
            return {containerClass : styles.primaryLabelContainerClass}; 
            break
        default:
            break
    }
}

const getFormatterHedearOptions = ({options, composedOptions, handleInsulinStatusUpdate})=>{
    return composedOptions.map((keys)=>{
            return {
                label: PATIENT_DETAILS_MAPPING[keys],
                customComponentProps : {
                    ...PATIENT_DETAILS_COMPONENT_MAPPING[keys],
                    handleInsulinStatusUpdate
                },
                value : getCorrespndingUserDetails(keys,options?.[keys]),
                ...getCorrespndingLabelProps(keys)
            }
    })
}

const getDurationChangeRequestBody =({prev,data, userId, name, payload}) =>{
    switch(name){
        case DURATION_CHANGE_TYPES.BASIC_LOGS:
            const {numberOfDays} = payload;
            return [{
                url : "dashboard/glucoseMetrics",
                requestBody : [
                    {
                        metricType: "AVG_FASTING_GLUCOSE",
                        numberOfDays : numberOfDays,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType:"FASTING_TIME_IN_RANGE",
                        numberOfDays: numberOfDays,
                        mealType :"BREAKFAST"
                    },
                ...getRequestBody({type:"FASTING_GLUCOSE",numberOfDays: 8,mealType:"BREAKFAST"})
                ]
            }];
        case DURATION_CHANGE_TYPES.DAILY_MONITORING_LOGS:
            const {prev} = payload;
            const {startDate, endDate} = getWeeklyFormattedWeeklyDates({prev, format:"YYYY-MM-DD"})
            return [{
                url : "dashboard/glucoseMetrics",
                requestBody : [
                    {
                        metricType: "AVG_FASTING_GLUCOSE",
                        numberOfDays : 7,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType:"FASTING_TIME_IN_RANGE",
                        numberOfDays: 7,
                        mealType :"BREAKFAST"
                    },
                ...getRequestBody({type:"FASTING_GLUCOSE",numberOfDays: 8,mealType:"BREAKFAST"}),
                ...getRequestBody({type: "OVERALL_TIME_IN_RANGE",numberOfDays: 8,mealType : "BREAKFAST",prev})]
            },
            {
                url : `nourishmentNotebook/dashboard/macronutrientsInsights/${userId}/${startDate}/${endDate}`,
                method : "GET"
            }]
        default:
            return {};
    }
}

const getNotesFormDataFormatter = (payload, userId, careProUserId) =>{
    return {
        ...payload,
        timeSpent : payload?.timeSpent?.[0].split(" ")?.[0],
        followUp : payload?.followUp ? moment(payload?.followUp || new Date()).format('YYYY-MM-DD') : "",
        dateOfReview : moment(payload?.dateOfReview || new Date()).format('YYYY-MM-DD'),
        userId, 
        careProUserId
    }
}

export {
    getFormatterHedearOptions,
    getRequestBody,
    getPatientDetailsFormattedData,
    getTabComponent,
    getTabProps,
    getRecentTrendsFormatter,
    getDurationChangeRequestBody,
    getNotesFormDataFormatter
}