const TIR_RANGE_TYPES = {
    above : "High",
    inRange : "Time-in-Range",
    below : "Low",
}

const TIR_RANGE_IDEAL_VALUES = {
    above : "More than 140",
    inRange : "63-140",
    below : "Less the 63",
}

export {
    TIR_RANGE_IDEAL_VALUES,
    TIR_RANGE_TYPES
}