import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";

import DeviceDetailsPopup from "./components/DeviceDetailsPopup";
import styles from "./device.module.css";

const DeviceCard = ({
        deviceDetails,
        handleDeviceListDrawerToggle
    }) => {

    const [deviceDetailsPopup, setDeviceDetailsPopup] = useState(false);

    const toggleDisplayDeviceDetailsPopup = useCallback(()=>{
        if(!deviceDetails?.length || deviceDetails?.length==0){
            return;
        }
        setDeviceDetailsPopup(!deviceDetailsPopup);
    },[setDeviceDetailsPopup,deviceDetailsPopup, deviceDetails]);

    const lastActive = useMemo(()=>{
        if(!deviceDetails?.[0]?.lastActive){
            return "No Data";
        }
        return moment(deviceDetails?.[0]?.lastActive).format("MMM DD, YYYY LT");
    },[deviceDetails]);

    const handleViewHistory = useCallback(()=>{
        handleDeviceListDrawerToggle();
        setDeviceDetailsPopup(false);
    },[setDeviceDetailsPopup, handleDeviceListDrawerToggle])
    
    return (
        <>
            <div className={styles.container} onClick={toggleDisplayDeviceDetailsPopup}>
                <div className={styles.leftContainer}>
                    <div className={styles.deviceDot}/>
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.labelTypo}>
                        FreeStyle Libre
                    </div>
                    <div className={styles.contentTypo}>
                        {`Last Active - ${lastActive}`}
                    </div>
                </div>
            </div>
            {deviceDetailsPopup && 
                <DeviceDetailsPopup
                    toggleModal={toggleDisplayDeviceDetailsPopup}
                    bodyProps={{
                        deviceDetails: deviceDetails?.[0],
                        handleViewHistory
                    }}
                />
            }
        </>
    )
}

export default DeviceCard;