import moment from "moment"
import { DEVICE_DETAIL_MAPPING } from "./deviceDetailCard.mapping"

const getFormattedDeviceManagmentContent = (type="",content="")=>{
    switch(type){
        case DEVICE_DETAIL_MAPPING[0]:
        case DEVICE_DETAIL_MAPPING[1]:
        case DEVICE_DETAIL_MAPPING[2]:
            return moment(content).format("MMM DD, YYYY");
        default:
            break;
    }
    return content
}

export {
    getFormattedDeviceManagmentContent
}