import React from "react";
import cx from "classnames";

import styles from "./primaryCardLabel.module.css";

const PrimaryCardLabel = ({label="",data=""})=>{
    return (
        <div className={cx(styles.container,styles.textTypo)}>
            {label} {data}
        </div>
    )
}

export default PrimaryCardLabel;