import React from "react";

import exclamationIcon from "./../../../../../../../../../../../assets/images/exclamation/basicExclamation.svg";

import styles from "./header.module.css";

const Header = ({CloseComponent}) => {
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <div className={styles.mainContentContainer}>
                    <span className={styles.contentLabel}>Device Management</span>
                    <img src={exclamationIcon} className={styles.exclamationIcon}/>
                </div>
                <div className={styles.secondaryContent}>
                    Active Glucose Tracking Device
                </div>
            </div>
            <div className={styles.rightContainer}>
                <CloseComponent />
            </div>
        </div>
    )
}

export default Header;