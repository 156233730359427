import React from "react";
import cx from "classnames";

import DateDisplay from "../../../../../../../../atoms/dateDisplay/DateDisplay";
import { getWeeklyFormattedWeeklyDates, getWeeklyFormattedWeeklyDatesOnStartDate } from "../../../../../../../../helpers/dateFormatter";
import ComparisonWeek from "../comparisonWeek";

import styles from "./comparisonAnalysisTable.module.css";

const getTableFields = (handleProgressMonitoringDurationChange, filterDate=1) =>{
    return [{
        id : 1,
        label: "Time Segment",
        name : "timing",
        className : cx(styles.tableColumnContainer, styles.primaryColumn, styles.tablePrimaryColumnContainer),
        innerClassName : styles.innerClassName
    },
    {
        id : 2,
        name : "gant2",
        label : <DateDisplay {...getWeeklyFormattedWeeklyDatesOnStartDate({format:"ddd, MMM DD"})}/>,
        className : cx(styles.tableColumnContainer, styles.tablePaddedContainer, styles.columnDateContainer),
        innerClassName : styles.innerClassName
    },
    {
        id : 3,
        name : "gant1",
        label : <ComparisonWeek name={"comparisonAnalysis"} onStartDate={true} handlePrimaryDurationChange={handleProgressMonitoringDurationChange} previous={filterDate} {...getWeeklyFormattedWeeklyDatesOnStartDate({startDate :filterDate,format:"MMM DD"})}/>,
        className : cx(styles.tableColumnContainer, styles.tablePaddedContainer, styles.columnDateContainer),
        innerClassName : styles.innerClassName
    },
]
}

export {
    getTableFields,
}