import React from "react";

import styles from "./dateInfoCard.module.css";

const DateInfoCard = ({label, content}) => {
    return (
        <div className={styles.container}>
            <div className={styles.mainLabel}>{label}</div>
            <div className={styles.mainContent}>{content}</div>
        </div>
    )
}

export default DateInfoCard;