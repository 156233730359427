import React from "react";
import cx from "classnames";

import exclamationIcon from "./../../../../../../../../../../../assets/images/exclamation/greyExclamation.svg";

import styles from "./cardHeader.module.css";

const CardHeader = ({label = "", content ="",className=""}) =>{
    return (
        <div className={cx(styles.container,className)}>
            <div className={styles.leftContainer}>
                <div className={styles.mainContentContainer}>
                    <div className={styles.mainContent}>
                        {label}
                    </div>
                    <img src={exclamationIcon} className={styles.exclamationIcon} />
                </div>
                <div className={styles.secondaryContent}>
                    {content}
                </div>
            </div>
        </div>
    )
}

export default CardHeader;