import styles from "./deviceDetailCard.module.css";

const ContentCard = ({label="",content=""})=>{
    return (
        <div className={styles.blockContainer}>
            <div className={styles.secondaryText}>
                {label}
            </div>
            <div className={styles.contentLabel}>
                {content}
            </div>
        </div>
    )
}

export {
    ContentCard
}