import React from "react";
import {map} from "lodash";

import styles from "./deviceManagementDrawerBody.module.css";
import DeviceDetailCard from "./components/DeviceDetailCard/DeviceDetailCard";

const DeviceManagementDrawerBody = ({
      deviceDetails =[]
    })=>{
    return (
        <div className={styles.container}>
            {map(deviceDetails,(deviceDetail)=>{
                return <DeviceDetailCard deviceDetail={deviceDetail}/>
            })}
        </div>
    )
}

export default DeviceManagementDrawerBody