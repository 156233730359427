import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import cx from "classnames";

import { map } from "lodash";
import dropDownBalancedPlate from "./../../../../../assets/images/dropDownBalancedPlate.svg";

import styles from "./customBasicDropdown.module.css";

const CustomBasicDropdown = ({
    value,
    options,
    errorTypes={}, 
    handleFieldChange=()=>()=>{}, 
    checkErrorState=()=>{}, 
    componentContainer="", 
    innerPrimaryContainer="",
    selectClassname="", 
    mainContentTypo="",
    handleMetricTypeChange=()=>{}, 
    name
  }) =>{

    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const [currentValue,setCurrentValue]=useState(value);
    const [currentOptionContainerWidth, setCurrentOptionContainerWidth] = useState(0);
    const [currentOptionContainerLeft, setCurrentOptionContainerLeft] = useState(0);

    const ref = useRef();

    useEffect(()=>{
      setCurrentValue(value);
    },[value]);

    useLayoutEffect(()=>{
      setCurrentOptionContainerWidth(ref.current.offsetWidth);
      setCurrentOptionContainerLeft(ref.current.offsetLeft);
    },[]);
  
    const toggleDropDown = () => {
      setIsDropDownVisible(!isDropDownVisible);
    };
  
    const openDropDown = () =>{
      setIsDropDownVisible(true);
    }
  
    const closeDropDown = () =>{
      setIsDropDownVisible(false);
    }

    const handleOptionChange = (value,label)=>{
        event.preventDefault();
        setCurrentValue(label)
        handleMetricTypeChange(name ,value)
        handleFieldChange({label:name})(value)
        checkErrorState(value,name,errorTypes)
    }

    return (
      <div className={cx(styles.container,componentContainer)}>
        <div className={cx(styles.headingMain, innerPrimaryContainer)}ref={ref}>
          <div className={styles.dropDownContainer} onMouseEnter={openDropDown} onMouseLeave={closeDropDown} >
            <div className={styles.dropDownMain}>
              <div className={cx(styles.progresscurrentValue, mainContentTypo)}>
                {currentValue}
              </div>
              <img 
                src={dropDownBalancedPlate} 
                className={styles.dropDownImage} 
                alt="" 
                onClick={toggleDropDown}
              />
            </div>
              <div 
                className={`${styles.dropDownOptionsMain} ${!isDropDownVisible ? styles.dropDownOptionsMainHidden : ''} ${selectClassname}`}
                style={{"minWidth": `${currentOptionContainerWidth}px`, marginLeft : `${currentOptionContainerLeft}px`}}
              >
                {map(options,({value,label})=>{
                    return (
                        <div className={styles.optionText} onClick={()=>{
                            setCurrentValue(value);
                            handleOptionChange(value,label)
                          }}>
                            {label}
                          </div>          
                    )
                })}
            </div>
          </div>
        </div>
      </div>
    );
}

export default CustomBasicDropdown;