import React from "react";

import cancelIcon from "./../../../../../assets/images/cancel/transparent_cacel.svg";

import styles from "./cancelComponent.module.css";

const CancelComponent = ({
      toggleDrawer = ()=>{},
      crossIcon
    })=>{

    return (
        <div className={styles.container} onClick={toggleDrawer}>
            <img src={crossIcon ? crossIcon :cancelIcon} className={styles.cancelIcon} />
        </div>
    )
}

export default CancelComponent;