import React, { useCallback } from "react";
import moment from "moment";

import WeeklyFilter from "../../../../../WeeklyFilter";
import { getWeeklyFormattedWeeklyDates } from "../../../../../../../../../../helpers/dateFormatter";
import DateFilter from "../../../../../../../../../../molecules/DateFilter";

import styles from "./header.module.css";
import { DATE_FORMATS } from "../../../../../../../../../../constants/dateFormats";

const Header = ({
        getFilterChangeAction= ()=>{},
        filterDuration,
    }) =>{

    const handleDateFilterUpdate = useCallback((startDate)=>{
        getFilterChangeAction({
            name : "current",
            startDate : moment(startDate,DATE_FORMATS.MONTH_DAY_YEAR).format("YYYY-MM-DD"),
            endDate : moment(startDate,DATE_FORMATS.MONTH_DAY_YEAR).add(6,'day').format("YYYY-MM-DD")
        })
    },[getFilterChangeAction]);

    return (
        <div className={styles.container}>
            <div className={styles.maintext}>
                Balanced Plate
            </div>
            <div className={styles.filterContainer}>
                <DateFilter
                    startDate={moment(filterDuration,"YYYY-MM-DD").format(DATE_FORMATS.MONTH_DAY_YEAR)}
                    endDate={moment(filterDuration,"YYYY-MM-DD").add(6,"day").format(DATE_FORMATS.MONTH_DAY_YEAR)}
                    handleDateUpdateAction={handleDateFilterUpdate}
                    format={"ddd, MMM DD"}
                />
            </div>
        </div>
    )
}

export default Header;