import React from "react";

import LeftContainer from "./containers/LeftContainer";

import styles from "./header.module.css";

const Header = ({options}) =>{
    return (
        <div className={styles.container}>
            <LeftContainer options={options}/>
        </div>
    )
}

export default Header;