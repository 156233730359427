import React from 'react';
import cx from "classnames";
import moment from 'moment';

import activeArrowLeft from "./../../../../../../../assets/images/arrow/active/basicLeftArrow.svg"
import activeArrowRight from "./../../../../../../../assets/images/arrow/active/basicRightArrow.svg";
import nonActiveRightArrow from "./../../../../../../../assets/images/arrow/nonActive/nonActiveRight.svg"

import { DATE_FORMATS } from '../../../../../../constants/dateFormats';

import styles from "./weeklyFilter.module.css";

const WeeklyFilter =  ({
        startDate="",
        containerClass="",
        endDate="",
        name,
        handlePrimaryDurationChange=()=>{},
        format=DATE_FORMATS.MONTH_DAY_YEAR,
        previous,
        onStartDate=false,
        maxDate=moment().format(DATE_FORMATS.MONTH_DAY_YEAR),
    }) =>{

    const handleLeftArrowClick = (event)=>{
        event.preventDefault();
        if(onStartDate){
            handlePrimaryDurationChange(name,moment(previous).subtract(7,'d').format('MM-DD-YYYY'));
            return;
        }
        handlePrimaryDurationChange(name,previous+1)
    }

    const handleRightArrowClick = (event) =>{
        event.preventDefault();
        if(onStartDate){
            handlePrimaryDurationChange(name,moment(previous).add(7,'d').format('MM-DD-YYYY'));
            return;
        }
        if(previous>0)
            handlePrimaryDurationChange(name, previous-1);
    }

    const dateArrowCheck = typeof previous!="number" ? moment(maxDate).isAfter(moment(endDate,format).add(7,'d').format(format)) : previous>0;

    return (
        <div className={cx(styles.container,containerClass)}>
            <img src={activeArrowLeft} onClick={handleLeftArrowClick} className={styles.arrowIcon}/>
            <div className={styles.innerContainer}>
                <span>{startDate}</span>
                <span>-</span>
                <span>{endDate}</span>
            </div>
            <img src={ dateArrowCheck ? activeArrowRight : nonActiveRightArrow} onClick={handleRightArrowClick} className={styles.arrowIcon}/>
        </div>
    )
}

export default WeeklyFilter;