import { useCallback } from "react";
import styles from "./search.module.css"

const Search = ({updateSearchText}) => {

    const handleSearchTextUpdate = useCallback((event)=>{
        updateSearchText(event?.target?.value)
    },[updateSearchText])

    return (

        <div className={styles.searchContainer}>
            <div className={styles.search}>
                <input onChange={handleSearchTextUpdate} placeholder="Search by Practice Name, Email ID" className={styles.searchBar}></input>
                <img src={require("../../../../../assets/images/search-icon.svg").default} className={styles.searchLogo}></img>
            </div>
            <div className={styles.rightActions}>
                <div className={styles.dropdown}>
                    <select className={styles.fieldValueDropdown}>
                        <option className={styles.fieldValueDropdown}>All Divisions</option>
                    </select>
                    <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                </div>

                <div className={styles.dropdown}>
                    <select className={styles.fieldValueDropdown}>
                        <option className={styles.fieldValueDropdown}>All Roles</option>
                    </select>
                    <img className={styles.dropdownArrow} src={require("../../../../../assets/images/dropdown_arrow.svg").default}></img>
                </div>
            </div>

        </div>

    )
}

export default Search;