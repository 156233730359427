import React from "react";

import styles from "./mealTableContentBlock.module.css";
import { COLOR_MAPPINGS } from "../../dailyMealLogs.constants";
import { ALERT_PRIORITY_MAPPING } from "../../dailyMealLogs.mapping";

const MealTabelContentBlock =({
        data ={}
    })=>{
    
    const { metricsData ={}} = data || {};

    const { glucose = "NA"} = metricsData || {};

    const icon = COLOR_MAPPINGS?.[metricsData?.["glucose-color-value"]?.split(" ")?.[0]] < 2 && ALERT_PRIORITY_MAPPING?.[COLOR_MAPPINGS?.[metricsData?.["glucose-color-value"]?.split(" ")?.[0]]];

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <span className={styles.mainContent}>
                    {glucose}
                </span>
                {icon && <img src={icon} className={styles.iconTypo} />}
            </div>
        </div>
    )
}

export default MealTabelContentBlock;