import { useState } from "react";
import PageHeaderContext from "./components/pageHeaderContext/PageHeaderContext";
import Heading from "./components/heading/Heading";
import Tabs from "./components/tabs/Tabs";

import useCoachManagement from "./customHooks/useCoachManagement";
import DefaultSpinner from "../../atoms/spinner";

import styles from "./coachDetails.module.css";

const CoachDetail = () => {

    const [isLoading, setIsLoading] = useState(false);

    const {coachDetails, getCoachDetails} = useCoachManagement();

    return (
        <>
            {isLoading && <DefaultSpinner />}
            <div className={styles.mainContainer}>
                <PageHeaderContext coachDetails={coachDetails} />
                <Heading setIsLoading={setIsLoading} coachDetails={coachDetails}/>
                <Tabs coachDetails={coachDetails} />
            </div>
        </>
    )
}

export default CoachDetail;