import { BASE_URL } from "../../../../constants/api";


const getArchiveActiveAction = async({
        userId
    })=>{
    try{
        const request = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/moveMemberToActive/${userId}`,{
            method : "GET",
            headers:{
                'content-type':'application/json',
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        });

        return request.status;
    }
    catch(e){
        console.log("ERROR : ",e);
    }
}

export {
    getArchiveActiveAction
}