const DATE_DATA_FORMAT = {
    lastActive : "MMM DD, YYYY LT",
    expiryDate : "MMM DD, YYYY",
    activationTimestamp : "MMM DD, YYYY"
}

const LABEL_MAPPING = {
    activationTimestamp : "Start Date",
    lastActive : "Last Active",
    expiryDate : "Expiry Date"
}

export {
    DATE_DATA_FORMAT,
    LABEL_MAPPING
}