import React, { useCallback } from "react";

import WeeklyFilter from "../../../WeeklyFilter";

import styles from "./comparisonWeek.module.css";
import moment from "moment";
import { DATE_FORMATS } from "../../../../../../../../constants/dateFormats";

const ComparisonWeek = ({
        handlePrimaryDurationChange=()=>{},
        ...rest
    }) =>{
    
    const handleDateChange = useCallback((name,date)=>{
        let currMinDate = moment().subtract(6,'d').format(DATE_FORMATS.MONTH_DAY_YEAR);
        let endDate = moment(date,DATE_FORMATS.MONTH_DAY_YEAR).add(6,'d').format(DATE_FORMATS.MONTH_DAY_YEAR);
        const dateCheck = moment(currMinDate).isAfter(endDate);
        if(dateCheck){
            handlePrimaryDurationChange(name,date)
        }
    },[handlePrimaryDurationChange])

    return (
        <div className={styles.container}>
            <div className={styles.mainLabel}>
                Comparison Week
            </div>
            <WeeklyFilter {...rest} maxDate={moment().format("MMM DD")} format={"MMM DD"} handlePrimaryDurationChange={handleDateChange}/>
        </div>
    )
}

export default ComparisonWeek;