import React from "react";
import {map} from "lodash";

import { DEVICE_DETAIL_LABEL_MAPPING, DEVICE_DETAIL_MAPPING } from "./deviceDetailCard.mapping";
import { ContentCard } from "./DeviceDetailCard.component";
import { getFormattedDeviceManagmentContent } from "./deviceDetailCard.helpers";

import styles from "./deviceDetailCard.module.css";

const DeviceDetailCard = ({
       deviceDetail={}
    }) => {

    const {deviceManufacturer="",deviceName="",serialNumber=""} = deviceDetail;
    
    return (
        <div className={styles.container}>
            <div className={styles.blockContainer}>
                <div className={styles.primaryBlock}>
                    <span className={styles.primaryTextTypo}>
                        {deviceManufacturer}
                    </span>
                    <span className={styles.contentLabel}>
                        {deviceName}
                    </span>
                </div>
                <div className={styles.secondaryText}>
                    {serialNumber}
                </div>
            </div>
            {map(DEVICE_DETAIL_MAPPING,(fieldname)=>(
                <ContentCard 
                    content={ getFormattedDeviceManagmentContent(fieldname, deviceDetail?.[fieldname])} 
                    label={DEVICE_DETAIL_LABEL_MAPPING?.[fieldname]} 
                />
            ))}
        </div>
    )
}

export default DeviceDetailCard