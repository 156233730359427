import { useCallback, useMemo, useState } from "react";

import Heading from "./components/heading/Heading";
import PageHeaderContext from "./components/pageHeaderContext/PageHeaderContext";
import Tabs from "./components/tabs/Tabs";
import Search from "./components/search/Search";
import EmptyBag from "./components/emptyBag/EmptyBag";
import Pagination from "./components/pagination/Pagination"
import AddNewMemberForm from "./components/form/AddNewMemberForm";
import useCoachManagement from "./hooks/useCoachManagement";

import Table from "./components/table/Table";
import useCoachForm from "./hooks/useCoachForm";

import useToast from "./hooks/useToast";
import BasicToast from "../../molecules/baseComponents/toasts/BasicToast";
import DefaultSpinner from "../../atoms/spinner/Spinner";

import withPagination from "../../hoc/withPagination";
import styles from "./rrxCoach.module.css";
import { getSearchTextFilteredList } from "./rrxCoach.helpers";

const RrxCoach = () => {

    const [isLoading , setIsLoading] = useState(false);

    const [searchText, setSearchText] = useState("");

    const {activeTab,coachList,updateActiveTab, resendInviteAction, updateCoachList} = useCoachManagement({tab : "ACTIVE", setIsLoading});

    const { formValues, submitFormValues, updateFormValues} = useCoachForm(updateActiveTab,updateCoachList);

    const [handleCloseToastDisplay=()=>{},handleOpenToastDisplay=()=>{},toastType,displayToast,message ] = useToast();

    const PaginatedTable = withPagination(Table);

    const filteredList = useMemo(()=> getSearchTextFilteredList(coachList,searchText),[coachList,searchText,getSearchTextFilteredList])

    const handleResendInviteAction = useCallback((id)=>{
        resendInviteAction(id)
        .then((data)=>{
            if(data=="200" || data=="201" || data=="204"){
                handleOpenToastDisplay("success","Invite Sent Successfully");
            }
            else{
                handleOpenToastDisplay("failed","Failed To Send Invite");
            }
        })
        .catch((e)=>{
            handleOpenToastDisplay("failed","Failed To Send Invite");
        })

    },[]);

    return (
        <div className={styles.mainContainer}>
            {displayToast && 
            <BasicToast 
                type={toastType} 
                display={displayToast} 
                handleToastToggle={handleCloseToastDisplay}
                message={message}
            />}
            {isLoading && <DefaultSpinner className={styles.loader} />}
            <PageHeaderContext />
            <Heading 
                setIsLoading={setIsLoading} 
                handleOpenToastDisplay={handleOpenToastDisplay} 
                submitFormValues={submitFormValues} 
                updateFormValues={updateFormValues} 
                formValues={formValues}
            />
            <Tabs 
                activeTab={activeTab} 
                updateActiveTab={updateActiveTab} 
            />
            <Search updateSearchText={setSearchText} />
            {coachList?.length>0 && 
                <PaginatedTable 
                    handleOpenToastDisplay={handleOpenToastDisplay} 
                    limit={10} 
                    resentInviteAction={handleResendInviteAction} 
                    setIsLoading={setIsLoading} 
                    tab={activeTab} 
                    tableData={filteredList} 
                    updateCoachList={updateCoachList}
                />}
            {coachList?.length==0 &&  
                <EmptyBag 
                    setIsLoading={setIsLoading} 
                    handleOpenToastDisplay={handleOpenToastDisplay} 
                    submitFormValues={submitFormValues} 
                    updateFormValues={updateFormValues} 
                    formValues={formValues}
                />}
        </div>
    )
}

export default RrxCoach;