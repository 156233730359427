import React from "react"

import styles from "./overViewTab.module.css"

const getBalancedPlateHeader = () =>{
    return (
        <div className={styles.balancedPlateLabelContainer}>
            <span className={styles.mainLabel}>Balanced Plate Summary</span>
            <span className={styles.betaTagContainer}> Beta </span>
        </div>
    )
}

export {
    getBalancedPlateHeader
}