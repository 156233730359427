import { useCallback, useEffect, useRef, useState } from "react";
import { getTextInitials } from "../../../../helpers/TextManipulations.helpers";

import AddNewPracticeFormStepTwo from "../newWorksiteForm/AddNewPracticeFormStepTwo";

import styles from "./heading.module.css";
import archivePractice from "./heading.async";
import { useNavigate } from "react-router-dom";

const Heading = ({ practiceName, practiceId, updateToast, setIsLoading = ()=>{} }) => {

    const [options, setOptions] = useState(false);

    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const containerDiv = useRef();

    useEffect(()=>{
        document.addEventListener("mousedown",handleCloseDisplayCalender);
    
        return () => {
          document.removeEventListener("mousedown", handleCloseDisplayCalender);
        };
    },[]);

    const handleCloseDisplayCalender = useCallback(()=>{
        if (containerDiv.current && !containerDiv.current.contains(event.target)) {
            setOptions(false);
        }
    },[setOptions, containerDiv]);

    const archivePracticeAction = useCallback(() => {
        setIsLoading(true);
        archivePractice({ practiceId })
        .then((response)=>{
            if(response.status == "200" || response.status == "201" || response.status == "204"){
                updateToast("success", "Moved To Archive Successfully")
                navigate("/superUser");
            }
            else{
                updateToast("failed", "Failed To Move To Archive")
            }
        })
        .catch((e)=>{
            console.log("ERROR : ",e);
        })
        .finally(()=>{
            setIsLoading(false);
        })
    },[navigate, archivePractice, setIsLoading]);

    return (

        <div className={styles.heading}>
            <div className={styles.practiceLogo}>
                {getTextInitials(practiceName)}
            </div>
            <div className={styles.practiceName}>
                {practiceName}
            </div>
            <div className={styles.buttonGroup} ref={containerDiv}>
                <div className={styles.buttonExport} onClick={() => { setOpen(true); }}>Add New Worksite</div>
                {open && <div className={styles.formBackground}>
                    <AddNewPracticeFormStepTwo setIsLoading={setIsLoading} updateToast={updateToast} setOpen={setOpen} className={styles.addNewPracticeFormStepOne}></AddNewPracticeFormStepTwo>
                </div>}
                <div className={styles.buttonExport}  onClick={() => { setOptions(!options) }}><img src={require("../../../../../assets/images/more_options.svg").default} /></div>
                {options && <div onClick={archivePracticeAction} className={styles.moveToArchive}><div className={styles.moveToArchiveContainer}>Move to Archive</div><div className={styles.moveToArchiveSpace}></div></div>}
            </div>
        </div>

    )
}

export default Heading;