import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getTextInitials } from "../../../../helpers/TextManipulations.helpers";
import { getMoveToArchieveAction } from "./heading.async";

import styles from "./heading.module.css";

const Heading = ({coachDetails={}, setIsLoading = ()=>{}}) => {

    const [options, setOptions] = useState(false);

    const navigate = useNavigate();
    const containerDiv = useRef();

    useEffect(()=>{
        document.addEventListener("mousedown",handleCloseDisplayCalender);
    
        return () => {
          document.removeEventListener("mousedown", handleCloseDisplayCalender);
        };
    },[]);

    const handleCloseDisplayCalender = useCallback(()=>{
        if (containerDiv.current && !containerDiv.current.contains(event.target)) {
            setOptions(false);
        }
    },[setOptions, containerDiv]);

    const {firstName="",lastName =""} = coachDetails;

    const handleMoveToArchieveAction = useCallback(()=>{
        setIsLoading(true)
        getMoveToArchieveAction()
        .then((response)=>{
            if(response.status=="200" || response.status=="201" || response.status=="204")
                navigate("/rrxCoach")
        })
        .catch((error)=>{
            console.log("ERROR : ",error);
        })
        .finally(()=>{
            setIsLoading(false);
        })
    },[setIsLoading,getMoveToArchieveAction])

    return (

        <div className={styles.heading}>
            <div className={styles.practiceLogo}>
                {getTextInitials(firstName+" "+lastName)}
            </div>
            <div className={styles.practiceName}>
                {firstName+" "+lastName}
            </div>
            <div className={styles.buttonGroup} ref={containerDiv}>
                <div className={styles.buttonExport} onClick={()=>{setOptions(!options)}}><img src={require("../../../../../assets/images/more_options.svg").default}/></div>
                {options && <div className={styles.moveToArchive} onClick={handleMoveToArchieveAction}>
                    <div className={styles.moveToArchiveContainer}>Move to Archive</div>
                </div>}
            </div>
        </div>
    )
}

export default Heading;