import React, { useCallback, useEffect, useState } from "react";
import Header from "./components/Header";

import Body from "./components/Body/Body";
import withActionState from "../../../../hooks/withActionState";
import actions from "./progressMonitoring.actions";
import actionTypes from "./progresssMonitoring.actionTypes";
import { getActivityAnalysisProps, getComparisonAnalysisColumns, getDateInOrder, getGlucoseDataPoints,  getStructureDeviceDetailData,  getTrendAnalysisMainData } from "./progressMonitoring.helper";
import { PROGRESS_MONITORING_TYPE } from "./progressMonitoring.constants";
import styles from "./progressMonitoring.module.css";
import SideDrawer from "../../../../molecules/sideDrawer";
import DeviceManagementDrawerHeader from "./components/DeviceManagment/DeviceManagementDrawerHeader";
import DeviceManagementDrawerBody from "./components/DeviceManagment/DeviceManagementDrawerBody/DeviceManagementDrawerBody";

const ProgressMonitoring = ({
        dispatch, 
        trendAnalysisMainData, 
        activityAnalysisData, 
        handleToggleLoaderAction, 
        currentInternalTabState, 
        recentTrends, 
        tableChartData=[], 
        filterDuration, 
        summary, 
        progressMonitoringType = PROGRESS_MONITORING_TYPE.BLOOD_GLUCOSE, 
        trendAnalysisTableData,
        handleToastUpdateAction,
        glucoseLogMetricsSourceTab,
        deviceDetails,
        comparisonMetricSegment={}
    }) =>{

    const [displayDeviceManagementDrawer, setDisplayDeviceManagementDrawer] = useState(false);

    useEffect(()=>{
        dispatch({actionType : actionTypes.INIT_ACTION});
    },[]);

    const handleDisplayDeviceManagementDrawer = useCallback(()=>{
        setDisplayDeviceManagementDrawer(!displayDeviceManagementDrawer);
    },[displayDeviceManagementDrawer]);

    const handleProgressMonitoringDurationChange = (payload)=>{
        dispatch({actionType : actionTypes.UPDATE_PROGRESS_MONITORING_DURATION, payload})
    };

    const handleGlucoseLogsTabUpdateAction = useCallback((payload) =>{
        dispatch({actionType : actionTypes.UPDATE_GLUCOSE_LOGS_TAB, payload})
    },[]);

    const getInternalTabUpdateAction = (payload)=>{
        dispatch({actionType : actionTypes.UPDATE_INTERNAL_TAB, payload})
    }

    const {trendAnalysisData, trendAnalysisColumns, trendAnalysisMin, trendAnaylsisMax} = getTrendAnalysisMainData(trendAnalysisMainData, filterDuration);

    return (
        <div className={styles.container}>
            {
                displayDeviceManagementDrawer &&
                <SideDrawer
                    HeaderComponent={DeviceManagementDrawerHeader}
                    BodyComponent={DeviceManagementDrawerBody}
                    toggleDrawer={handleDisplayDeviceManagementDrawer}
                    bodyProps={{
                        deviceDetails : getStructureDeviceDetailData(deviceDetails)
                    }}
                />
            }
            <Header 
                handleProgressMonitoringDurationChange={handleProgressMonitoringDurationChange} 
                getInternalTabUpdateAction={getInternalTabUpdateAction} 
                progressMonitoringType={progressMonitoringType}
                filterDuration={filterDuration?.current}
                deviceDetails={getStructureDeviceDetailData(deviceDetails)}
                handleDeviceListDrawerToggle={handleDisplayDeviceManagementDrawer}
            />
            <Body 
                handleToggleLoaderAction={handleToggleLoaderAction} 
                trendAnalysisMainData={trendAnalysisData} 
                recentTrends={recentTrends} 
                progressMonitoringType={ currentInternalTabState ? currentInternalTabState :progressMonitoringType} 
                trendAnalysisTableData={trendAnalysisTableData} 
                activityAnalysisData={getActivityAnalysisProps(activityAnalysisData)} 
                comparisonAnalysisColumns={getComparisonAnalysisColumns()} 
                filterDuration={filterDuration} 
                handleProgressMonitoringDurationChange={handleProgressMonitoringDurationChange} 
                summary={summary} 
                categories={trendAnalysisColumns} 
                tableChartData={tableChartData}
                handleToastUpdateAction={handleToastUpdateAction}
                glucoseLogMetricsSourceTab={glucoseLogMetricsSourceTab}
                handleGlucoseLogsTabUpdateAction={handleGlucoseLogsTabUpdateAction}
                trendAnalysisMin={trendAnalysisMin}
                trendAnaylsisMax={trendAnaylsisMax}
                comparisonMetricSegment={comparisonMetricSegment}
            />
        </div>
    )
}

export default withActionState({ Component: ProgressMonitoring, action : actions });