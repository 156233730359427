import React from "react";
import {map} from "lodash";
import cx from "classnames"

import styles from "./secondaryHeader.module.css";

const SecondaryHeader = ({
        dateList=[],
        activeDate="",
        handleActiveDateUpdate=()=>{}
    })=>{
    
    return (
        <div className={styles.outerContainer}>
        <div className={styles.borderContainer} />
        <div className={styles.container}>
            {map(dateList,({
                date,
                alertIcon,
                formattedDate
            })=>{
                return (
                    <div className={cx(styles.dateItem,{[styles.activeDate]: activeDate==date})} onClick={handleActiveDateUpdate(date)}>
                        <span className={cx(styles.dateContent,{[styles.activeContent]: activeDate==date})}>
                            {formattedDate}
                        </span>
                        <img 
                            src={alertIcon} 
                            className={styles.alertIcon}
                         />
                    </div>
                )
            })}
        </div>
        <div className={styles.borderContainer} />
        </div>
    )
}

export default SecondaryHeader;