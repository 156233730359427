import { filter } from "lodash"

const getFilteredPatientList = (patientList,searchText) =>{
    return filter(patientList,(patientListItem)=>{
        const {email ="" } = patientListItem;

        return email?.toLowerCase()?.includes(searchText?.toLowerCase()) || ((patientListItem?.["first-name"] || "")+(patientListItem?.["last-name"] || ""))?.toLowerCase()?.includes(searchText?.toLowerCase());
    })
}

export {
    getFilteredPatientList
}