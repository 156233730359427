import React from "react";
import {map} from "lodash";

import ThreeRowDataDisplay from "../../../../../threeRowDataDisplay/ThreeRowDataDisplay";
import infoIcon from "./../../../../../../../../../assets/images/exclamation/basicExclamation.svg";
import { PROGRESS_MONITORING_PRIMARY_CARD_TYPES } from "../../../../progressMonitoring.options";

import styles from "./glucoseMetricsCard.module.css";

const GlucoseMetricsCard = ({options=PROGRESS_MONITORING_PRIMARY_CARD_TYPES, data={} }) =>{

    const GetInfoIcon = (keys)=>{
        return (<>{keys}{"   "}<img src={infoIcon} className={styles.icon}/></>)
    }

    const GetSecondaryContent = (primaryCard,name, suffix)=>{

        if(!primaryCard?.[name]){
            return (
                <div className={styles.secondaryContentContainer}>
                    <span className={styles.secondaryText}>
                        No Data
                    </span>
                </div>
            )
        }

        return (
            <div className={styles.secondaryContentContainer}>
                <span className={styles.secondaryText}>
                    {primaryCard?.[name] && `${primaryCard?.[name] || "-"}`}
                </span>
                <span className={styles.suffix}>
                    {primaryCard?.[name] ? suffix : ""}
                </span>
            </div>
        )
    }

    const { primaryCard = []} = data;
    
    return (
        <div className={styles.container}>
            {map(options,(option)=>{
                const {name, suffix, label,goal} = option;
                return <ThreeRowDataDisplay primaryClassname={styles.primaryText} secondaryClassname={styles.secondaryText} primaryContent={GetInfoIcon(label)} secondaryContent={GetSecondaryContent(primaryCard, name, suffix)}/>
            })}
        </div>
    )
}

export default GlucoseMetricsCard;